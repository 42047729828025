import { navigate } from "gatsby";
import React from "react";

const GoBack = (): JSX.Element | null => {
  return (
    <p className="read-more">
      <a
        className="go-back-button"
        onClick={(): Promise<void> => navigate(-1)}
        tabIndex={0}
        href="#"
      >
        Zurück
        <span className="icon-arrow-left" aria-hidden="true" />
      </a>
    </p>
  );
};

export default GoBack;
