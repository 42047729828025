import "dayjs/locale/de";
import { graphql } from "gatsby";
import React from "react";
import { Layout, ScrollToTopBtn } from "../components";
import Feed from "../components/Feed";
import Footer from "../components/Footer";
import GoBack from "../components/GoBack";
import Header from "../components/Header";
import { Props } from "../models/props";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String, $strapiId: Int) {
    sitePage(path: { eq: $url }) {
      id
    }
    allArticle(filter: { tags: { elemMatch: { id: { eq: $strapiId } } } }) {
      edges {
        node {
          tags {
            title
            color
          }
          image_blogfeed {
            id
            alternativeText
            caption
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          childMarkdownRemark {
            frontmatter {
              date
              title
              excerpt
            }
            fields {
              url
              articleFeedId
            }
          }
          externalUrl
        }
      }
    }
  }
`;

const TagFeed = ({ data, pageContext, ...props }: Props): JSX.Element => {
  const articlesEdges = data?.allArticle?.edges;
  const title = pageContext.frontmatter?.title;

  return (
    <Layout {...props} site={pageContext.site} page={pageContext}>
      <Header site={pageContext.site} page={pageContext} image={undefined} />
      <div id="content" className="site-content">
        <main id="main" className="site-main inner">
          <article className="post page post-full">
            <header className="post-header">
              <h1 className="post-title">{title}</h1>
            </header>
          </article>
          <Feed articleEdges={articlesEdges} pageContext={pageContext} />
        </main>
        <ScrollToTopBtn />
        <GoBack />
        <Footer site={pageContext.site} />
      </div>
    </Layout>
  );
};
export default TagFeed;
